import React, { useState, useEffect } from "react";
import { MinimalButton, ScrollMode, SpecialZoomLevel, Viewer, ViewMode, Worker } from '@react-pdf-viewer/core';
import { thumbnailPlugin, ThumbnailDirection } from "@react-pdf-viewer/thumbnail";
import { NextIcon, pageNavigationPlugin, PreviousIcon } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from "@react-pdf-viewer/zoom"; // Import the zoom plugin
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css"; // Import zoom plugin styles

const Magazine = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const isMobile = screenWidth <= 768; // Define mobile breakpoint

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToNextPage, jumpToPreviousPage } = pageNavigationPluginInstance;

    const thumbnailPluginInstance = thumbnailPlugin();
    const { Thumbnails } = thumbnailPluginInstance;

    const zoomPluginInstance = zoomPlugin(); // Initialize the zoom plugin
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

    // Update screen width on resize
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <div
                style={{
                    border: "1px solid rgba(0, 0, 0, .3)",
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                }}
            >
                <div
                    style={{
                        borderBottom: "1px solid rgba(0, 0, 0, .3)",
                        height: "40rem",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "1rem",
                            transform: "translate(0, -100%) rotate(-90deg)",
                            zIndex: "1",
                        }}
                    >
                        <MinimalButton onClick={jumpToPreviousPage}>
                            <PreviousIcon />
                        </MinimalButton>
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            top: "1rem",
                            right: "1rem",
                            display: "flex",
                            gap: "0.5rem",
                            zIndex: "1",
                        }}
                    >
                        <ZoomOutButton />
                        <ZoomPopover />
                        <ZoomInButton />
                    </div>
                    <Viewer
                        key={isMobile ? "mobile" : "desktop"} // Force re-render when viewMode changes
                        defaultScale={SpecialZoomLevel.PageFit}
                        scrollMode={ScrollMode.Page}
                        viewMode={isMobile ? ViewMode.SinglePage : ViewMode.DualPage} // Responsive view mode
                        fileUrl="/zine.pdf" // Set the file URL here
                        plugins={[pageNavigationPluginInstance, thumbnailPluginInstance, zoomPluginInstance]} // Add zoom plugin
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            right: "1rem",
                            transform: "translate(0, -100%) rotate(-90deg)",
                            zIndex: "1",
                        }}
                    >
                        <MinimalButton onClick={jumpToNextPage}>
                            <NextIcon />
                        </MinimalButton>
                    </div>
                </div>
                <div
                    style={{
                        height: "12rem",
                        overflow: "auto",
                    }}
                >
                    <Thumbnails thumbnailDirection={ThumbnailDirection.Horizontal} />
                </div>
            </div>
        </Worker>
    );
};

export default Magazine;