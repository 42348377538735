// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeScreen from "./pages/Home";
import Navbar from "./components/Navbar";
import OrderScreen from "./pages/Order"
import MagazineScreen from "./pages/Magazine";


function App() {
    return (
        <Router>

            <Navbar />
            <Routes>
                {/* Home Screen Route */}
                <Route path="/" element={<HomeScreen />} />
                <Route path="/order" element={<OrderScreen />} />
                <Route path="/zine" element={<MagazineScreen />} />

            </Routes>
        </Router>
    );
}

export default App;