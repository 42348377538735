import React, { useEffect, useRef, useState } from 'react';
import logo from '../components/images/logovermelho.png';
import TestimonialCarousel from '../components/TestimonialCarousel';

import { useLocation } from 'react-router-dom';
import Navbar from "../components/Navbar";
import { BrowserRouter as Router, Link } from "react-router-dom";
import EmailForm from '../components/EmailForm';
import storyImageSmall from '../components/images/storyyy.png';
import storyImage from '../components/images/desktop-story.jpg';

import optionsImage from '../components/images/Options.jpg';
import title from '../components/images/title.jpg';
import finalProduct from '../components/images/finalProduct.jpg';
import contactImage from '../components/images/contactUs.png';
import artistsImage from '../components/images/artists2.png';
import titleImage from '../components/images/catchytitle.png';
import whatImage from '../components/images/What.png';
import howImage from '../components/images/How.png';
import whyImage from '../components/images/Why.png';
import linkedinIcon from '../components/images/linkedin.png';
import instagramIcon from '../components/images/instagram.png';
import '../App.css';
import leftImage from '../components/images/team.png'
import socialsImage from '../components/images/socials.png'
import socialsMobile from '../components/images/socials_mobile.png'
import desktoptitle from '../components/images/desktop-title.jpg'




function Home() {
    const logoRef = useRef(null);
    const [currentStoryImage, setCurrentStoryImage] = useState(title);
    const [currentSocialsImage, setCurrentSocialsImage] = useState(socialsImage);
    const rectangleItemRefs = useRef([]);
    const { state } = useLocation(); // Access state passed from Navbar

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 750) {
                setCurrentStoryImage(title);
                setCurrentSocialsImage(socialsMobile);
            } else {
                setCurrentStoryImage(desktoptitle);
                setCurrentSocialsImage(socialsImage);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Scroll to section if state.sectionId is present
    useEffect(() => {
        if (state?.sectionId) {
            setTimeout(() => {
                const element = document.getElementById(state.sectionId);
                if (element) {
                    const offset = 80; // Height of the navbar
                    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                    const offsetPosition = elementPosition - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                    });
                }
            }, 100); // Delay of 500 milliseconds (adjust as needed)
        }
    }, [state]);



    useEffect(() => {
        const handleScroll = () => {
            const scaleFactor = Math.min(window.scrollY / 500, 1);
            if (logoRef.current) {
                logoRef.current.style.transform = `scale(${1 + scaleFactor})`;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const setEqualHeights = () => {
            const heights = rectangleItemRefs.current.map(ref => ref.querySelector('p').clientHeight);
            const maxHeight = Math.max(...heights);

            rectangleItemRefs.current.forEach(ref => {
                ref.querySelector('p').style.height = `${maxHeight}px`;
            });
        };

        setEqualHeights();
        window.addEventListener('resize', setEqualHeights);

        return () => {
            window.removeEventListener('resize', setEqualHeights);
        };
    }, []);

    return (
        <div className="App">
           {/* <Router>
                <Navbar />
            </Router>*/}
            <header className="App-header" id="header">
                <img src={logo} className="App-logo" alt="logo" ref={logoRef} />
                {/*<h1>DESIGN<del style={{ color: 'red' }}>ER</del> CLOTHING</h1>*/}
            </header>



            <section className="story-section" id="story-section">
                {/* Add the new button here */}
                <Link to="/zine">
                    <button
                        style={{
                            backgroundColor: '#d8131c',
                            border: '2px solid black',
                            color: 'white',
                            padding: '10px 20px',
                            fontSize: '20px',
                            cursor: 'pointer',
                            borderRadius: '5px',
                            marginTop: '20px',
                            //animation: 'flashBackground 0.1 \\\\\\\\\s infinite alternate', // Add animation
                        }}
                    >
                        Tap in! Read the Scraps Zine!
                    </button>
                </Link>
                {/*<img src={titleImage} alt="Catchy Title" className="title-image" />*/}
                <p className="story-text-main" >
                    GIVE YOUR OLD CLOTHES A NEW LIFE
                </p>

                <p className="story-text">
                    SCRAPS is your plug for cool and creative artists that are eager to make your clothes into unique and sustainable pieces.
                </p>
                <p className="story-text">
                    Take a look in your closet, see which pieces need some love and check which artists can make it possible in our <b style={{ color: '#d8131c'}}>Artists’ Catalog</b>.
                </p>

                {/*<img className="image-opt" src={optionsImage} />*/}

                <div
                    style={{
                        position: 'relative',
                        width: '100%',
                        maxWidth: '800px', // Cap the size for larger screens
                        height: 'auto',
                        margin: 'auto',
                        marginTop: '1.5em',
                        marginBottom: '1.5em',
                        //boxShadow: '0 2px 8px 0 rgba(63, 69, 81, 0.16)',
                        overflow: 'hidden',
                        //borderRadius: '8px',
                        display: 'block',
                    }}
                >
                    <iframe
                        loading="lazy"
                        style={{
                            width: '100%',
                            height: 'auto',
                            aspectRatio: '16 / 9', // Maintain a 16:9 ratio for video or image content
                            border: 'none',
                            padding: '0',
                            margin: '0',
                            display: 'block',
                        }}

                        src="https://www.canva.com/design/DAGYiwwYXQQ/UPjzw9bNgbEQi2LIcJQjJQ/view?embed" allowFullScreen="allowfullscreen" allow="fullscreen">
                    </iframe>
                </div>
                <a href="https://scraps-artistscatalog.my.canva.site/" target="_blank" rel="noopener">SCRAPS - Artists' Catalog</a> by SCRAPS



                {/* <a  href="https://scraps-artistscatalog.my.canva.site/" target="_blank" rel="noopener noreferrer" >
                    <button className="artists-catalog-button">ARTISTS' CATALOG</button>
                </a>*/}



            </section>
            {/*  <img className="storyImage" src={currentStoryImage} alt="Story" style={{ width: '100%', height: 'auto' }} />*/}


            <section className="story-section"  style={{ alignItems:"center"}} id="order-section">
                {/*<div className="artist-image-container">
                    <img src={artistsImage} alt="Catchy Title" className="artist-image" />
                </div>*/}


                <p className="story-text-main" >
                    LIFE’S SHORT. TAKE RISKS.
                </p>
                <p className="story-text">
                    Fill out the <b style={{ color: '#d8131c'}}>order form</b> with your ideas (or lack of them) to <b> transform your piece</b>. You can <b> ask for a specific artist </b> from our catalog or <b>we can suggest you</b> one.
                </p>

                <img className="image-opt2" src={finalProduct} />


                <p className="story-text">
                    Whether you're full of inspiration or have no idea what you want, <b>fill out the d*mn form and create a cool new piece</b>.
                </p>
                <Link to="/order">
                    <button className="artists-catalog-button">ORDER NOW</button>
                </Link>




            </section>

            <section className="story-section" id="cta-section">
                {/*<div className="artist-image-container">
                    <img src={artistsImage} alt="Catchy Title" className="artist-image" />
                </div>*/}



                <img className="image-title" src={currentStoryImage} />

                <p className="story-text" style={{ color: '#d8131c'}}>
                    Be at the forefront of the SCRAPS’ movement.
                </p>



                <p className="story-text">
                    Join our exclusive group to conduct our first projects, understand your needs create unique and sustainable pieces!
                </p>

                <div className="cta-button-container">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScchg8t1EXf9zpoPM11zAPZDXLIg-4ytYzupEWHFQmFbmZQoA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">
                        <button className="artists-catalog-button">COUNT ME IN!</button>
                    </a>
                </div>



            </section>

            <section className="supporting-content-section" id="supporting-content-section">
                <TestimonialCarousel />
            </section>

            {/* <section className="red-rectangle-section" id="red-rectangle-section">
                <div className="rectangle-item" ref={el => rectangleItemRefs.current[2] = el}>
                    <img src={whyImage} alt="Why" className="title-image" />
                    <p>The power of community can revolutionize the fashion industry
                    </p>
                </div>
                <div className="rectangle-item" ref={el => rectangleItemRefs.current[1] = el}>
                    <img src={howImage} alt="How" className="title-image" />
                    <p>Through collaborative design projects</p>

                </div>
                <div className="rectangle-item" ref={el => rectangleItemRefs.current[0] = el}>
                    <img src={whatImage} alt="What" className="title-image" />
                    <p>An app that allows artists to co-create with customers</p>
                </div>


            </section>
*/}




            <section className="email-form-section" id="email-form-section">

                <p className="story-text-main" style={{ margin: '0 0 10px 0' }}>
                    STILL GOT QUESTIONS?
                </p>
                <p className="story-text" style={{ color: '#d8131c'}}>
                    Shoot us a DM and follow our journey online!<br />
                    <a href="mailto:scrapspt@gmail.com" style={{ color: 'black', textDecoration: 'none' }}>
                        scrapspt@gmail.com
                    </a>
                </p>

                <div className="footer-right">
                    <a href="https://www.linkedin.com/company/scrapsstudios" target="_blank" rel="noopener noreferrer">
                        <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
                    </a>
                    <a href="https://www.instagram.com/scraps.pt/" target="_blank" rel="noopener noreferrer">
                        <img src={instagramIcon} alt="Instagram" className="social-icon" />
                    </a>
                </div>

                <div className="form-container">
                    <img src={leftImage} alt="Descriptive Alt Text" className="side-image" />
                    {/*<EmailForm />*/}
                </div>
                {/*<iframe
                    id="JotFormIFrame-242283218139052"
                    title="SCRAPS - Order Now"
                    onLoad={() => window.parent.scrollTo(0, 0)}

                    allowTransparency="true"
                    allow="geolocation; microphone; camera; fullscreen"
                    src="https://form.jotform.com/scrapspt/order"
                    frameBorder="0"
                    style={{
                        minWidth: '100%',
                        maxWidth: '100%',
                        height: '539px',
                        border: 'none'
                    }}

                    scrolling="yes"
                >
                </iframe>
                <script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>
                <script>window.jotformEmbedHandler("iframe[id='JotFormIFrame-242283218139052']",
                    "https://form.jotform.com/")
                </script>*/}


            </section>


            <footer className="footer-section">

                <p>&copy; 2024 SCRAPS</p>
            </footer>
        </div>
    );
}

export default Home;
